import styled from 'styled-components';
import { themeGet } from 'styled-system';

const PageOverview = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 25px;

  .overview-meta {
    display: block;
    text-align: center;
    width: 100%;
    margin: 5% 0;

    @media only screen and (max-width: 1440px) {
      margin: 8% 0;
    }

    h3 {
      margin: 0;
      font-style: italic;

      @media only screen and (max-width: 1440px) {
        font-size: 2rem;
      }
    }

    .post-count {
      font-size: 1.3rem;
      position: relative;
      font-family: 'Inter', sans-serif;
      margin-top: 25px;
      padding-top: 25px;

      @media only screen and (max-width: 1440px) {
        font-size: 1.2rem;
        margin-top: 20px;
        padding-top: 20px;
      }

      &::before {
        content: '';
        width: 50px;
        height: 1px;
        background-color: ${themeGet('color.2')};
        position: absolute;
        left: calc(50% - 50px / 2);
        top: 0;
      }
    }
  }

  .masonry-grid {
    display: flex;
    margin-left: -16px;
    width: 100%;
    padding-bottom: 2rem;
  }
  .masonry-grid_column {
    padding-left: 16px;
    background-clip: padding-box;
    .content-card {
      margin-bottom: 17px;
    }
    .post-card {
      padding: 25px;
      background-color: ${themeGet('color.7')};
      border-radius: 5px;
      margin-bottom: 17px;
    }
  }
`;

export default PageOverview;

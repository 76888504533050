import React from 'react';
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Masonry from 'react-masonry-css';
import SEO from '../components/Seo/Seo';
import PostCard from '../components/PostCard/PostCard';
import Layout from '../layout';
import PageOverview from './template.style';

export default ({ pageContext, data }) => {
  const allWordpressPost = data.allWordpressPost.edges;
  const masonryColumns = {
    default: 4,
    1600: 3,
    991: 2,
    500: 1,
  };

  return (
    <Layout>
      <SEO title={pageContext.name} />

      <PageOverview className="page-overview">
        <div className="overview-meta">
          <h3 dangerouslySetInnerHTML={{ __html: pageContext.name }} />
          <div className="post-count">{allWordpressPost.length} resultaten</div>
        </div>

        <Masonry
          breakpointCols={masonryColumns}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {allWordpressPost.map(post => (
            <Fade key={post.node.id} bottom>
              <PostCard
                date={post.node.date}
                title={post.node.title}
                excerpt={
                  post.node.childWordPressAcfIntro &&
                  post.node.childWordPressAcfIntro.text
                }
                path={post.node.path}
              />
            </Fade>
          ))}
        </Masonry>
      </PageOverview>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    allWordpressPost(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          date(formatString: "DD MMMM YYYY", locale: "nl_NL")
          title
          path
          childWordPressAcfIntro {
            text
          }
        }
      }
    }
  }
`;
